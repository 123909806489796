import { useEffect, useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
import PropTypes from 'prop-types';
import 'assets/stylesheets/App.scss';
import usePreloadedQueryWrapper from 'hooks/relayWrappers/usePreloadedQueryWrapper';

const CurrentUserLoader = ({ query, queryRef }) => {
  const { setCurrentUser } = useContext(CurrentUserContext);
  const data = usePreloadedQueryWrapper(
    query,
    queryRef,
  );

  if (!data) return null;

  useEffect(() => {
    setCurrentUser(data.currentUser);
  }, [queryRef]);

  return null;
};

CurrentUserLoader.propTypes = {
  query: PropTypes.instanceOf(Object).isRequired,
  queryRef: PropTypes.instanceOf(Object).isRequired,
};

export default CurrentUserLoader;
