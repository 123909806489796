import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routes from 'utils/routes';

import 'components/Layout/Header/LoginBar.scss';

const RegisterBar = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <span>{t('header.registerNewAccount')}</span>
      <Link className="btn btn-sm btn-link btn-login" to={routes.companyRegistration}>
        {t('register')}
      </Link>
    </>
  );
};

export default RegisterBar;
