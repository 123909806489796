async function fetchGraphQL(params, variables) {
  const { REACT_APP_GRAPHQL_URL } = process.env;

  const headers = { 'Content-Type': 'application/json' };

  const response = await fetch(REACT_APP_GRAPHQL_URL, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });
  const jsonResponse = await response.json();
  if (jsonResponse.errors && !jsonResponse.data?.serverErrors) {
    throw jsonResponse.errors[0];
  }
  return jsonResponse;
}

export default fetchGraphQL;
