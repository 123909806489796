import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  ACCEPT_EMAIL_CONFIRMATION_STATUS,
  ALREADY_CONFIRMED_EMAIL_CONFIRMATION_STATUS,
  REJECT_EMAIL_CONFIRMATION_STATUS,
} from 'utils/constants';

const ProfileToast = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('toasts');

  useEffect(() => {
    const confirmationStatus = searchParams.get('confirmation');

    if (confirmationStatus) {
      const statusNotifyType = {
        [REJECT_EMAIL_CONFIRMATION_STATUS]: 'error',
        [ACCEPT_EMAIL_CONFIRMATION_STATUS]: 'success',
        [ALREADY_CONFIRMED_EMAIL_CONFIRMATION_STATUS]: 'info',
      };
      const confirmationNotifyType = statusNotifyType[confirmationStatus];
      if (confirmationNotifyType) {
        // eslint-disable-next-line dot-notation
        toast[confirmationNotifyType](t(`confirmationStatus.${confirmationStatus}`));
        searchParams.delete('confirmation');
        setSearchParams(searchParams.toString());
      }
    }
  }, [searchParams]);

  return null;
};

export default ProfileToast;
