import { useEffect, useRef, useCallback } from 'react';

export default () => {
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
  });

  return useCallback(() => isMountedRef.current, []);
};
