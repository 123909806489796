import { Container } from 'react-bootstrap';
import Loader from './Loader';

export default function MainContentLoader() {
  return (
    <Container fluid className="min-height mh-80 d-flex justify-content-center align-items-center">
      <Loader />
    </Container>
  );
}
