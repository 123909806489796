import React from 'react';
import PropTypes from 'prop-types';
import AppImage from 'components/Shared/AppImage/AppImage';
import { Link } from 'react-router-dom';
import AppButton from 'components/Shared/AppButton/AppButton';

import 'components/Shared/EmptyState/empty-state.scss';

export default function EmptyState({
  imageSrc,
  emptyTitle,
  titleClass,
  emptyDesc,
  className,
  emptyStateHeight,
  imageHeight,
  btnText,
  btnLink,
  variant,
  children,
  onClick,
}) {
  let button = null;
  if (btnText) {
    if (btnLink) {
      button = (
        <Link to={btnLink}>
          <AppButton type="button" variant={variant}>
            {btnText}
          </AppButton>
        </Link>
      );
    } else if (onClick) {
      button = (
        <AppButton type="button" variant={variant} onClick={onClick}>
          {btnText}
        </AppButton>
      );
    }
  }
  return (
    <div className={`${className} empty-state text-center`} style={{ height: emptyStateHeight }}>
      <div className="card-content">
        <AppImage src={imageSrc} imageHeight={imageHeight} height={imageHeight} className="empty-state-image" />
        <h6 className={titleClass}>{emptyTitle}</h6>
        <div className="hint">{emptyDesc}</div>
        {children}
        { button }
      </div>
    </div>
  );
}
EmptyState.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  emptyTitle: PropTypes.string.isRequired,
  emptyDesc: PropTypes.string,
  className: PropTypes.string,
  emptyStateHeight: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  variant: PropTypes.string,
  imageHeight: PropTypes.string,
  titleClass: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

EmptyState.defaultProps = {
  emptyDesc: '',
  className: '',
  emptyStateHeight: '',
  imageHeight: '60',
  titleClass: 'h6',
  variant: '',
  btnText: '',
  btnLink: '',
  children: null,
  onClick: null,
};
