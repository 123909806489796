import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { RelayEnvironmentProvider } from 'react-relay';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import RelayEnvironment from 'RelayEnvironment';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import Loader from 'components/Shared/Loader/Loader';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;

if (['staging', 'production'].includes(NODE_ENV)) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    environment: NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <Suspense fallback={<Loader />}>
      <StrictMode>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </StrictMode>
    </Suspense>
  </RelayEnvironmentProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
