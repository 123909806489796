export const CURRENT_USER_KEY = 'currentUser';
export const MONTHLY_SUBSCRIPTION_KEY = 'monthly';
export const YEARLY_SUBSCRIPTION_KEY = 'yearly';
export const FREE_TRIAL_PLAN_TYPE = 'trial';
export const ESSENTIAL_PLAN_TYPE = 'essential';
export const PROFESSIONAL_PLAN_TYPE = 'professional';
export const ENTERPRISE_PLAN_TYPE = 'enterprise';
export const COMPANY_USER_TYPE = 'CompanyUser';
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const INSTAGRAM = 'instagram';
export const TIKTOK = 'tiktok';
export const YOUTUBE = 'youtube';
export const TWITTER = 'twitter';
export const SNAPCHAT = 'snapchat';
export const SOCIAL_PLATFORMS = [INSTAGRAM, TIKTOK, YOUTUBE];
export const PENDING_PAYMENT_STATUS = 'pending';
export const ACCEPTED_PAYMENT_STATUS = 'confirmed';
export const REJECTED_PAYMENT_STATUS = 'rejected';
export const FLICRON_WEBSITE_LINK = 'https://flicron.com/';
export const ACCEPT_EMAIL_CONFIRMATION_STATUS = 'confirmed';
export const ALREADY_CONFIRMED_EMAIL_CONFIRMATION_STATUS = 'alreadyConfirmed';
export const REJECT_EMAIL_CONFIRMATION_STATUS = 'rejected';
/**
 * date-fns Format
 * 1 January 2021
 * @see https://date-fns.org/v2.28.0/docs/format
 */
export const LONG_DATE_FORMAT = 'dd MMMM yyyy';
export const TOAST_DELAY = 225;
export const MANUALLY_ADDED_INFLUENCER_KEY = 'manually_added';
export const HYPEAUDITOR_ADDED_INFLUENCER_KEY = 'hypeauditor';
export const INFLUENCER_REPORT_EXPIRATION_IN_DAYS = 90;
export const TIKTOK_VIDEO_URL = 'https://www.tiktok.com/@:username/video/:videoId';
export const YOUTUBE_VIDEO_URL = 'https://www.youtube.com/watch?v=:videoId';
export const YOUTUBE_CHANNEL_URL = 'https://www.youtube.com/channel/:channelId';
export const TASK_STATUSES = ['PENDING', 'IN_REVIEW', 'ACCEPTED', 'DONE'];
