import { parsePhoneNumber } from 'react-phone-number-input';
import { arSA, enUS } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { generatePath } from 'react-router-dom';
import { currentLanguage } from 'i18n';

export const dateDiffInDays = (date1, date2) => (
  Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24))
);

export const getDateFromDateTime = (date) => (
  new Date(date).toLocaleDateString()
);

const allCountries = (locale) => {
  /* eslint-disable global-require */
  const countries = require('i18n-iso-countries');
  // eslint-disable-next-line import/no-dynamic-require
  countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));
  /* eslint-enable global-require */
  return countries.getNames(locale, { select: 'official' });
};

export const countriesList = (locale, { codes } = {}) => {
  const countriesObject = allCountries(locale);
  const keys = codes || Object.keys(countriesObject);
  return keys.map((code) => ({
    value: code,
    label: countriesObject[code],
  }));
};

export const countryName = (locale, code) => (
  allCountries(locale)[code]
);

export const KMFormatter = (number) => {
  if (!number) return number;
  // return number;
  if (number < 999) return Math.sign(number) * Math.abs(number);
  if (number < 999999) return `${Math.sign(number) * ((Math.abs(number) / 1000).toFixed(1))}k`;

  return `${Math.sign(number) * ((Math.abs(number) / 1000000).toFixed(1))}M`;
};

export const formatMobileNumber = (mobileNumber) => (
  mobileNumber.replace(/^00/g, '+')
);

export const isSubscriptionExpired = (endedAtDate) => {
  const remainingDays = dateDiffInDays(new Date(endedAtDate), new Date());

  return (remainingDays < 0 || !endedAtDate);
};

export const formattedMobileNumber = (mobileNumber) => {
  const phoneNumber = parsePhoneNumber(mobileNumber);
  return phoneNumber ? `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}` : null;
};

export const isEmptyObject = (object) => !Object.values(object).some((x) => x !== null && x !== '' && x.length !== 0);

/**
 * Capitalizes the first letter of the given word
 * E.g. Converts "hello" to "Hello"
 *
 * @param {String} word
 * @returns {String}
 */
export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Prefix the given field with the given locale
 *
 * Example:
 *
 * Consider the input:
 *  fieldName = 'name'
 *  lang = 'en'
 *
 * The output will be 'nameEn'
 *
 * @param {String} fieldName
 * @param {String} language
 * @returns {String}
 */
export function getLocalizeField(filedName, language = null) {
  return `${filedName}${capitalize(language || currentLanguage())}`;
}

const DATE_FNS_LOCALES = {
  ar: arSA,
  en: enUS,
};

/**
 *
 * @param {String} language one of ("en", "ar"), defaults to current language
 * @returns {String}
 */
export function dateFnsLocale(language = null) {
  return DATE_FNS_LOCALES[language || currentLanguage()];
}

export const isBlank = (value) => (
  value === ''
  || value === null
  || value === undefined
  || (Array.isArray(value) && value.length === 0)
);

/**
 * Remove all the keys that have blank values.
 * Blank values are {'', null, undefined, and []}
 * @param {object} input
 */
export const removeBlanks = (input) => {
  const result = {};
  Object.keys(input).forEach((key) => {
    if (!isBlank(input[key])) {
      result[key] = input[key];
    }
  });

  return result;
};

export const toastify = (status, message, delay = 225, options = {}) => {
  toast.dismiss();
  toast[status](message, { delay, ...options });
};

export const chunkArray = (array, chunkSize) => (
  Array(Math.ceil(array.length / chunkSize)).fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))
);

export const routeTo = (path, params, query) => {
  const url = generatePath(path, params);
  if (!query) return url;

  const queryString = Object.keys(query).map((key) => (`${key}=${query[key]}`)).join('&');

  return `${url}?${queryString}`;
};

export const truncateString = (str, length) => {
  if (str.length <= length) { return str; }
  const subString = str.slice(0, length - 1);
  return `${subString.slice(0, subString.lastIndexOf(' '))} ...`;
};

export const optionsValues = (options) => {
  if (!options) return null;

  if (Array.isArray(options)) {
    return options.map((item) => item.value);
  }

  return options.value;
};

export const formatPrice = (price, decimals = 2) => price.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
