import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
// eslint-disable-next-line import/no-unresolved
import { useIntercom } from 'react-use-intercom';

import 'components/Layout/Footer/Footer.scss';

function Footer() {
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const { boot } = useIntercom();

  useEffect(() => {
    boot({ language_override: i18n.language });
  }, [currentUser?.fullName]);

  return (
    <footer className="footer p-3 bg-primary mt-5">
      <Container fluid={currentUser}>
        <Row className="text-center text-lg-start">
          <Col sm={12} lg={6}>
            <p className="copyrights mb-0">{t('footer.copyrights')}</p>
          </Col>
          <Col sm={12} lg={6}>
            <div className="d-flex justify-content-center justify-content-lg-end quick-links">
              <a className="link" target="_blank" rel="noreferrer" href={process.env.REACT_APP_TERMS_URL}>{t('footer.links.termsConditions')}</a>
              <a className="link" target="_blank" rel="noreferrer" href={process.env.REACT_APP_PRIVACY_URL}>{t('footer.links.privacyPolicy')}</a>
              <a className="link" target="_blank" rel="noreferrer" href={process.env.REACT_APP_FAQ_URL}>{t('footer.links.FAQ')}</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
export default Footer;
