import { CURRENT_USER_KEY, COMPANY_USER_TYPE, FREE_TRIAL_PLAN_TYPE } from 'utils/constants';

export const removeStoredUser = () => {
  localStorage.removeItem(CURRENT_USER_KEY);
};

export const storedCompanyUserData = (user) => {
  const discoverySubscription = user?.company?.discoverySubscription;
  return (
    {
      dbId: user.dbId,
      fullName: user.fullName,
      /* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
      type: user.__typename,
      email: user.email,
      hasCurrentEmailConfirmed: user.hasCurrentEmailConfirmed,
      unconfirmedEmail: user.unconfirmedEmail,
      hasUnconfirmedEmail: user.hasUnconfirmedEmail,
      mobileNumber: user.mobileNumber,
      mobileNumberVerified: user.mobileNumberVerified,
      jobTitle: user?.jobTitle,
      company: {
        dbId: user.company?.id,
        name: user.company?.name,
        vatNumber: user.company?.vatNumber,
        commercialRecord: user.company?.commercialRecord,
        address: user.company?.address,
        postalCode: user.company?.postalCode,
        canSubscribeToPlan: user.company?.canSubscribeToPlan,
        canCreateIrmList: user.company?.canCreateIrmList,
        canRequestReport: user.company?.canRequestReport,
      },
      discoverySubscription: {
        dbId: discoverySubscription?.dbId,
        planType: discoverySubscription?.planType,
        startedAt: discoverySubscription?.startedAt,
        endedAt: discoverySubscription?.endedAt,
        searchTimes: discoverySubscription?.searchTimes,
        monthlyResults: discoverySubscription?.monthlyResults,
        remainingSearchTimes: discoverySubscription?.remainingSearchTimes,
        remainingMonthlyResults: discoverySubscription?.remainingMonthlyResults,
        remainingInfluencersStorage: discoverySubscription?.remainingInfluencersStorage,
        socialNetworks: discoverySubscription?.socialNetworks,
        price: discoverySubscription?.price,
        subscriptionType: discoverySubscription?.subscriptionType,
      },
    }
  );
};

export const saveLoggedInCompanyUser = (company) => {
  localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(storedCompanyUserData(company)));
};

export const storedCompanyUser = () => (
  JSON.parse(localStorage.getItem(CURRENT_USER_KEY))
);

export const isCompanyUser = (user) => (
  user?.type === COMPANY_USER_TYPE
);

export const userHasPurchasedPlan = (user) => (
  user.company.discoverySubscription?.planType
  && user.company.discoverySubscription.planType !== FREE_TRIAL_PLAN_TYPE
);
