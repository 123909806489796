import { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { NOT_FOUND_ERROR, FORBIDDEN_ERROR } from './errorCodes';
import NotFoundError from './NotFoundError';
import InternalServerError from './InternalServerError';

const { NODE_ENV } = process.env;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (NODE_ENV === 'development') {
      console.error(error);
      console.error(errorInfo);
    }
    if (['staging', 'production'].includes(NODE_ENV)) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      if ([NOT_FOUND_ERROR, FORBIDDEN_ERROR].includes(error.message)) {
        return <NotFoundError />;
      }
      return <InternalServerError />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
