// A wrapper for <Route> that redirects to login if not authenticated
import { Outlet } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay/hooks';
import currentUserQuery from 'components/Layout/currentUserQuery';
import ExpiredTrial from '../ExpiredPlan/ExpiredPlan';

const ActiveSubscriptionRoute = () => {
  const { currentUser } = useLazyLoadQuery(currentUserQuery);
  return currentUser?.company?.subscription
    ? <Outlet />
    : <ExpiredTrial />;
};

export default ActiveSubscriptionRoute;
