/* jshint sub:true */

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { REJECTED_PAYMENT_STATUS, ACCEPTED_PAYMENT_STATUS, PENDING_PAYMENT_STATUS } from 'utils/constants';

const PaymentToast = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('toasts');

  useEffect(() => {
    const paymentStatus = searchParams.get('payment-status');

    if (paymentStatus) {
      const statusNotifyType = {
        [REJECTED_PAYMENT_STATUS]: 'error',
        [ACCEPTED_PAYMENT_STATUS]: 'success',
        [PENDING_PAYMENT_STATUS]: 'info',

      };
      const paymentNotifyType = statusNotifyType[paymentStatus];
      if (paymentNotifyType) {
        // eslint-disable-next-line dot-notation
        toast[paymentNotifyType](t(`paymentStatus.${paymentStatus}`));
        searchParams.delete('payment-status');
        setSearchParams(searchParams.toString());
      }
    }
  }, [searchParams]);

  return null;
};

export default PaymentToast;
