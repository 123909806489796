import { graphql } from 'babel-plugin-relay/macro';
// TODO delete discoverySubscription after removing all its ocurrences from the app
const currentUserQuery = graphql`
 query currentUserQuery {
    currentUser {
      ... on CompanyUser {
        dbId
        id
        fullName
        email
        hasCurrentEmailConfirmed
        resendConfirmationBlockedUntil
        unconfirmedEmail
        hasUnconfirmedEmail
        mobileNumber
        mobileNumberVerified
        jobTitle
        __typename
        company {
          name
          vatNumber
          commercialRecord
          address
          postalCode
          companyType
          canActivateCampaign
          canSubscribeToPlan
          canCreateIrmList
          canRequestReport
          downgradablePlans{
            dbId
            planType
          }
          discoverySubscription {
            dbId
            id
            name
            planType
            startedAt
            endedAt
            searchTimes
            monthlyResults
            remainingSearchTimes
            remainingMonthlyResults
            remainingInfluencersStorage
            influencersPerCampaign
            socialNetworks
            price
            subscriptionType
            renewable
            influencersStorage
            remainingInfluencersStorage
            influencersPerCampaign
            irmListsLimit
            activeCampaigns
            monthlyReports
            requestedReports
            actualCampaignsInfluencers
            irmListsCount
            campaignsCount
            exportResults
            exportPdf
            remainingActiveCampaigns
          }
          subscription {
            dbId
            id
            name
            planType
            startedAt
            endedAt
            searchTimes
            monthlyResults
            remainingSearchTimes
            remainingInfluencersStorage
            influencersPerCampaign
            remainingMonthlyResults
            socialNetworks
            price
            subscriptionType
            renewable
            influencersStorage
            remainingInfluencersStorage
            influencersPerCampaign
            irmListsLimit
            activeCampaigns
            monthlyReports
            requestedReports
            actualCampaignsInfluencers
            irmListsCount
            campaignsCount
            customWorkflows
            exportResults
            exportPdf
            remainingActiveCampaigns
          }
        }
      }
    }
    serverErrors(field: "currentUser") {
      statusCode
    }
  }
`;

export default currentUserQuery;
