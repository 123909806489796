import UserData from 'components/Layout/Header/UserData';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes from 'utils/routes';

const CompanyNavbar = () => {
  const { t } = useTranslation('common');
  const location = useLocation();

  const activeStateClass = (path, exact = false) => {
    if (exact) {
      return location.pathname === path ? 'active' : '';
    }
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  return (
    <>
      <div className="navbar-links d-flex me-auto" navbarscroll="true">
        <Link className={`nav-link ${activeStateClass(routes.home, true)}`} to={routes.home}>
          {t('header.menu.main')}
        </Link>
        <Link className={`nav-link ${activeStateClass(routes.discoverInfluencers)}`} to={routes.discoverInfluencers}>
          {t('header.menu.discoverInfluencers')}
        </Link>
        <Link className={`nav-link ${activeStateClass(routes.influencers.myInfluencers)}`} to={routes.influencers.myInfluencers}>
          {t('header.menu.influencers')}
        </Link>
        <Link className={`nav-link ${activeStateClass(routes.campaigns.index)}`} to={routes.campaigns.index}>
          {t('header.menu.campaigns')}
        </Link>
      </div>
      <div className="ms-auto d-flex align-items-center" navbarscroll="true">
        <UserData />
      </div>
    </>
  );
};

export default CompanyNavbar;
