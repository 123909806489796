import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

export default function AppButton(
  {
    id,
    name,
    type,
    disabled,
    children,
    isLoadMore,
    href,
    onClick,
    size,
    variant,
    className,
  },
) {
  return (
    <Button
      id={id}
      name={name}
      className={`${className} ${isLoadMore ? 'btn-more' : ''}`}
      type={type}
      variant={variant}
      size={size}
      disabled={disabled}
      href={href}
      onClick={onClick}
    >

      {children}
    </Button>
  );
}
AppButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
  isLoadMore: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

AppButton.defaultProps = {
  id: '',
  name: '',
  variant: 'primary',
  disabled: false,
  children: null,
  type: 'button',
  size: '',
  href: null,
  isLoadMore: false,
  onClick: null,
  className: '',
};
