import { useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
import * as Errors from 'components/Errors/errorCodes';

const useServerErrorHandler = (serverErrors) => {
  const { logoutUser } = useContext(CurrentUserContext);

  let errorCode;

  serverErrors.forEach(({ statusCode }) => {
    if (Errors.SERVER_ERRORS.includes(statusCode.toString())) {
      errorCode = statusCode;
      return false;
    }
    return true;
  });
  if (errorCode?.toString() === Errors.UNAUTHENTICATED_ERROR) {
    logoutUser();
    return null;
  }

  throw new Error(errorCode);
};

export default useServerErrorHandler;
