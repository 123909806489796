import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import AppButton from 'components/Shared/AppButton/AppButton';
import { useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
import { dateDiffInDays } from 'utils/helpers';
import routes from 'utils/routes';

function StickyBar() {
  const location = useLocation();
  const { t } = useTranslation('messages');
  const { currentUser } = useContext(CurrentUserContext);

  const endedAtDate = new Date(currentUser.discoverySubscription.endedAt);
  const remainingDays = dateDiffInDays(endedAtDate, new Date());
  let message;
  if (remainingDays > 0) {
    message = t('trialExpiresIn', { remainingDays: t('common:day', { count: remainingDays }) });
  } else {
    message = t('trialExpired');
  }
  return (
    <section className="p-2 bg-primary">
      <Container fluid className="text-white">
        <span className="size-6">{message}</span>
        { location.pathname !== routes.plans.discovery && (
          <Link to={routes.plans.discovery}>
            <AppButton className="btn-inverse ms-2">
              {t('planPrice')}
            </AppButton>
          </Link>
        )}
      </Container>
    </section>
  );
}

export default StickyBar;
