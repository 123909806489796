import { useTranslation } from 'react-i18next';
import error500 from 'assets/images/500.png';
import ErrorPage from './ErrorPage';

function InternalServerError() {
  const { t } = useTranslation('errors', 'common');
  return (
    <ErrorPage message={t('internalServerError')} description={t('internalServerErrorDes')} image={error500} />
  );
}

export default InternalServerError;
