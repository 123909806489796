import { useTranslation } from 'react-i18next';
import EmptyState from 'components/Shared/EmptyState/EmptyState';
import TimeOutImage from 'assets/images/time-out.png';
import AppButton from 'components/Shared/AppButton/AppButton';
import routes from 'utils/routes';
import { Link } from 'react-router-dom';

import 'components/ExpiredPlan/expired-plan.scss';

const ExpiredTrial = () => {
  const { t } = useTranslation('messages');
  return (
    <div className="f-vh d-flex flex-column justify-content-center align-items-center">
      <EmptyState
        className="expired-trial"
        imageSrc={TimeOutImage}
        emptyTitle={t('expiredPlanTitle')}
        emptyDesc={t('expiredPlanDesc')}
      />
      <Link to={routes.plans.discovery}>
        <AppButton className="btn-inverse">
          {t('goProNow')}
        </AppButton>
      </Link>
    </div>
  );
};

export default ExpiredTrial;
