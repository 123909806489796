import { useTranslation } from 'react-i18next';
import error404 from 'assets/images/404.png';
import ErrorPage from './ErrorPage';

function NotFoundError() {
  const { t } = useTranslation('errors', 'common');
  return (
    <ErrorPage message={t('notFound')} description={t('notFoundDes')} image={error404} />
  );
}

export default NotFoundError;
