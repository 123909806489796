import { Link, useLocation } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import LoginBar from 'components/Layout/Header/LoginBar';
import AppImage from 'components/Shared/AppImage/AppImage';
import StickyBar from 'components/StickyBar/StickyBar';
import CompanyNavbar from 'components/Layout/Header/CompanyNavbar';
import BrandLogo from 'assets/images/brand-logo.png';
import routes from 'utils/routes';
import { useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
import { FREE_TRIAL_PLAN_TYPE, FLICRON_WEBSITE_LINK } from 'utils/constants';
import 'components/Layout/Header/Header.scss';
import { isCompanyUser } from 'utils/auth';
import RegisterBar from 'components/Layout/Header/RegisterBar';

const Header = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const location = useLocation();
  const appLogo = (
    <AppImage
      src={BrandLogo}
      className="brand-logo"
      imageHeight="20"
    />
  );

  return (
    <>
      <Navbar bg="white" expand="lg" className="mb-0">
        <Container fluid={currentUser}>
          <Navbar.Brand>
            { currentUser && isCompanyUser(currentUser)
              ? (
                <Link to={routes.home}>
                  {appLogo}
                </Link>
              )
              : (
                <a href={FLICRON_WEBSITE_LINK} target="_blank" rel="noreferrer">
                  {appLogo}
                </a>
              )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {currentUser && isCompanyUser(currentUser) && <CompanyNavbar />}
            {!currentUser && (
              <div className="ms-auto d-flex align-items-center" navbarscroll>
                <Nav className="d-flex align-items-center">
                  {location.pathname === routes.login || location.pathname === routes.home
                    ? <RegisterBar /> : <LoginBar />}
                </Nav>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {
        isCompanyUser(currentUser) && (
          currentUser?.discoverySubscription?.planType === FREE_TRIAL_PLAN_TYPE
          || !currentUser?.discoverySubscription?.planType
        )
        && <StickyBar />
      }
    </>
  );
};

export default Header;
