import { Image } from 'react-bootstrap';
// TODO return lazyload
// import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';

export default function AppImage({
  src, imageHeight, className, ...props
}) {
  return (
    // <LazyLoad height={imageHeight}>
    <Image src={src} className={className} {...props} />
    // </LazyLoad>
  );
}

AppImage.propTypes = {
  src: PropTypes.string.isRequired,
  imageHeight: PropTypes.string,
  className: PropTypes.string,
};

AppImage.defaultProps = {
  className: '',
  imageHeight: '',
};
