import { usePreloadedQuery } from 'react-relay';
import useServerErrorHandler from 'hooks/useServerErrorHandler';

const usePreloadedQueryWrapper = (query, queryRef, options = {}) => {
  const data = usePreloadedQuery(query, queryRef, options);
  if (data.serverErrors?.length) {
    useServerErrorHandler(data.serverErrors);
    return null;
  }
  return data;
};

export default usePreloadedQueryWrapper;
