import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import registrationEn from 'locales/en/registration.json';
import registrationAr from 'locales/ar/registration.json';
import commonEn from 'locales/en/common.json';
import commonAr from 'locales/ar/common.json';
import validationsEn from 'locales/en/validations.json';
import validationsAr from 'locales/ar/validations.json';
import messagesEn from 'locales/en/messages.json';
import messagesAr from 'locales/ar/messages.json';
import influencersAr from 'locales/ar/influencers.json';
import influencersEn from 'locales/en/influencers.json';
import plansEn from 'locales/en/plans.json';
import plansAr from 'locales/ar/plans.json';
import profileAr from 'locales/ar/profile.json';
import profileEn from 'locales/en/profile.json';
import toastsEn from 'locales/en/toasts.json';
import toastsAr from 'locales/ar/toasts.json';
import transactionsEn from 'locales/en/transactions.json';
import transactionsAr from 'locales/ar/transactions.json';
import errorsEn from 'locales/en/errors.json';
import errorsAr from 'locales/ar/errors.json';
import influencerReportEn from 'locales/en/influencerReport.json';
import influencerReportAr from 'locales/ar/influencerReport.json';
import campaignsEn from 'locales/en/campaigns.json';
import campaignssAr from 'locales/ar/campaigns.json';
import dashboardAr from 'locales/ar/dashboard.json';
import dashboardEn from 'locales/en/dashboard.json';

const resources = {
  ar: {
    registration: registrationAr,
    common: commonAr,
    validations: validationsAr,
    plans: plansAr,
    profile: profileAr,
    messages: messagesAr,
    influencers: influencersAr,
    toasts: toastsAr,
    transactions: transactionsAr,
    errors: errorsAr,
    influencerReport: influencerReportAr,
    campaigns: campaignssAr,
    dashboard: dashboardAr,
  },
  en: {
    registration: registrationEn,
    common: commonEn,
    validations: validationsEn,
    plans: plansEn,
    profile: profileEn,
    messages: messagesEn,
    influencers: influencersEn,
    toasts: toastsEn,
    transactions: transactionsEn,
    errors: errorsEn,
    influencerReport: influencerReportEn,
    campaigns: campaignsEn,
    dashboard: dashboardEn,
  },
};

i18n.on('missingKey', (lngs, namespace, key) => {
  throw new Error(`Missing translation key: ${namespace}:${key}`);
});

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar',
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: process.env.NODE_ENV === 'development',
  });

export default i18n;

export function currentLanguage() {
  return i18n.language;
}
