import {
  useState, useMemo, useEffect, Suspense,
} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'components/Routes/Routes';
import Header from 'components/Layout/Header/Header';
import Footer from 'components/Layout/Footer/Footer';
import CurrentUserContext from 'contexts/CurrentUser';
import { saveLoggedInCompanyUser, storedCompanyUser, removeStoredUser } from 'utils/auth';
import { useQueryLoader } from 'react-relay';
import 'assets/stylesheets/App.scss';
import useIsMounted from 'hooks/useIsMounted';
import currentUserQuery from 'components/Layout/currentUserQuery';
import CurrentUserLoader from 'components/Layout/CurrentUserLoader';
import PaymentToast from 'components/Layout/PaymentToast';
import { IntercomProvider } from 'react-use-intercom';
import MainContentLoader from 'components/Shared/Loader/MainContentLoader';
import ProfileToast from './components/CompanyUser/Profile/ProfileToast';
import { PaginationMapperContextProvider } from './contexts/PaginationMapperContext';

const App = () => {
  const storedUser = storedCompanyUser();
  const [currentUser, setCurrentUser] = useState(storedUser);
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  const currentUserContextValue = useMemo(() => ({
    currentUser,
    setCurrentUser: (user) => {
      if (user) {
        saveLoggedInCompanyUser(user);
      }
      setCurrentUser(storedCompanyUser);
    },
    logoutUser: () => {
      removeStoredUser();
      setCurrentUser(null);
    },
  }), [currentUser]);

  const isMounted = useIsMounted();
  const [queryRef, loadQuery] = useQueryLoader(currentUserQuery);

  useEffect(() => {
    if (isMounted() && currentUser) {
      loadQuery();
    }
  }, [isMounted]);

  return (
    <PaginationMapperContextProvider>
      <CurrentUserContext.Provider value={currentUserContextValue}>
        {queryRef
          && <CurrentUserLoader query={currentUserQuery} queryRef={queryRef} />}
        <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID}>
          <ToastContainer
            position="bottom-left"
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
          <PaymentToast />
          <ProfileToast />
          <Header />
          <Suspense fallback={<MainContentLoader />}>
            <Routes />
          </Suspense>
          <Footer />
        </IntercomProvider>
      </CurrentUserContext.Provider>
    </PaginationMapperContextProvider>
  );
};

export default App;
