import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routes from 'utils/routes';

import 'components/Layout/Header/LoginBar.scss';

const LoginBar = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <span>{t('header.alreadyHaveAccount')}</span>
      <Link className="btn btn-sm btn-link btn-login" to={routes.login}>
        {t('login')}
      </Link>
    </>
  );
};

export default LoginBar;
