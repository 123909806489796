// A wrapper for <Route> that redirects to homepage if authenticated
import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import routes from 'utils/routes';
import CurrentUserContext from 'contexts/CurrentUser';
import { isCompanyUser } from 'utils/auth';

const UnauthenticatedRoute = () => {
  const { currentUser } = useContext(CurrentUserContext);
  return currentUser && isCompanyUser(currentUser)
    ? <Navigate to={routes.discoverInfluencers} /> : <Outlet />;
};

export default UnauthenticatedRoute;
