import { useTranslation } from 'react-i18next';
import AppImage from 'components/Shared/AppImage/AppImage';
import AppButton from 'components/Shared/AppButton/AppButton';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ErrorPage({
  message, description, image, linkTo, linkText,
}) {
  const { t } = useTranslation('common');
  return (
    <Container className="min-height mh-80" fluid>
      <Row>
        <Col className="text-center pt-5 mt-5">
          <AppImage src={image} height="330" className="my-3" />
          <h5 className="text-black">
            {message}
          </h5>
          <p className="font-tajawal-regular size-6 text-dark">
            {description}
          </p>
          <a href={linkTo}>
            <AppButton variant="primary">
              {linkText || t('backToHome')}
            </AppButton>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

ErrorPage.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
};

ErrorPage.defaultProps = {
  linkTo: '/',
  linkText: null,
};

export default ErrorPage;
