// A wrapper for <Route> that redirects to login if not authenticated
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import routes from 'utils/routes';
import CurrentUserContext from 'contexts/CurrentUser';

const AuthenticatedRoute = () => {
  const location = useLocation();
  const { currentUser } = useContext(CurrentUserContext);
  return currentUser ? <Outlet /> : <Navigate to={routes.login} state={{ redirectTo: location }} />;
};

export default AuthenticatedRoute;
