import { Nav, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import routes from 'utils/routes';
import { useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUser';
import AppImage from 'components/Shared/AppImage/AppImage';
import avatar from 'assets/images/avatar-placeholder.png';
import { useNavigate } from 'react-router-dom';

function UserData() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { currentUser, logoutUser } = useContext(CurrentUserContext);

  return (
    <>
      { currentUser?.discoverySubscription?.planType
      && (
      <Nav className="typography-3 border-end pe-3">
        {t('header.menu.remainingResult')}
        {
          currentUser.discoverySubscription.remainingMonthlyResults
        }
      </Nav>
      )}
      <Dropdown className="fc-dropdown d-inline mx-2">
        <Dropdown.Toggle
          id="dropdown-autoclose-true"
          size="sm"
          className="px-2"
        >
          <AppImage src={avatar} className="avatar" imageHeight="20" />
          <span className="d-inline-block me-2">{currentUser.fullName}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu size="sm">
          <Dropdown.Item onClick={() => { navigate(routes.plans.discovery); }}>
            <span className="d-inline-block">{t('header.menu.pricePlans')}</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => { navigate(routes.companyProfile.show); }}>
            <span className="d-inline-block">{t('header.menu.personalProfile')}</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={logoutUser}>
            {t('logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default UserData;
