import {
  Routes as ReactRoutes,
  Route,
} from 'react-router-dom';
import { lazy } from 'react';
import routePaths from 'utils/routes';
import AuthenticatedRoute from 'components/Routes/AuthenticatedRoute';
import UnauthenticatedRoute from 'components/Routes/UnauthenticatedRoute';
import ActiveSubscriptionRoute from './ActiveSubscriptionRoute';

const CompanySignUp = lazy(() => import('components/Registration/CompanySignUp'));
const ChooseAccount = lazy(() => import('components/Registration/ChooseAccount/ChooseAccount'));
const Login = lazy(() => import('components/Login/Login'));
const DiscoveryPlans = lazy(() => import('components/DiscoveryPlans/DiscoveryPlans'));
const NotFoundError = lazy(() => import('components/Errors/NotFoundError'));
const SearchInfluencersPage = lazy(() => import('components/DiscoveryInfluencer/SearchInfluencersPage'));
const InfluencersPage = lazy(() => import('components/InfluencerManagement/InfluencerManagement'));
const InfluencerListsPage = lazy(() => import('components/InfluencerManagement/InfluencerLists/InfluencerLists'));
const InfluencerMyListPage = lazy(() => import('components/InfluencerManagement/InfluencerLists/myList'));
const CompanyUserProfile = lazy(() => import('components/CompanyUser/Profile/ShowProfile/ShowProfile'));
const CompanyEditProfile = lazy(() => import('components/CompanyUser/Profile/EditProfile/EditProfile'));
const CompanySubscriptionDetails = lazy(() => import('components/CompanyUser/Profile/Subscription/SubscriptionDetails'));
const CompanyTransactions = lazy(() => import('components/CompanyUser/Profile/Transactions/TransactionHistory'));
const CampaignsManagement = lazy(() => import('components/CampaignsManagement/CampaignsManagement'));
const CampaignsDetails = lazy(() => import('components/CampaignsManagement/CampaignDetails'));
const CampaignInfluencersStatistics = lazy(
  () => import('components/CampaignsManagement/InfluencerStatistics/CampaignInfluencersStatistics'),
);
const InfluencerProfile = lazy(() => import('components/InfluencerProfile/InfluencerProfile'));
const CompanyDashboard = lazy(() => import('components/CompanyDashboard/CompanyDashboard'));

const Routes = () => (
  <ReactRoutes>
    <Route element={<UnauthenticatedRoute />}>
      <Route exact path={routePaths.companyRegistration} element={<CompanySignUp />} />
      <Route exact path={routePaths.chooseAccount} element={<ChooseAccount />} />
      <Route exact path={routePaths.login} element={<Login />} />
    </Route>

    <Route element={<AuthenticatedRoute />}>
      <Route exact path={routePaths.plans.discovery} element={<DiscoveryPlans />} />
      <Route exact path={routePaths.companyProfile.show} element={<CompanyUserProfile />} />
      <Route exact path={routePaths.companyProfile.editProfile} element={<CompanyEditProfile />} />
      <Route
        exact
        path={routePaths.companyProfile.subscriptionDetails}
        element={<CompanySubscriptionDetails />}
      />
      <Route
        exact
        path={routePaths.companyProfile.transactions}
        element={<CompanyTransactions />}
      />
      <Route element={<ActiveSubscriptionRoute />}>
        <Route exact path={routePaths.home} element={<CompanyDashboard />} />
        <Route exact path={routePaths.discoverInfluencers} element={<SearchInfluencersPage />} />
        <Route exact path={routePaths.influencers.myInfluencers} element={<InfluencersPage />} />
        <Route
          exact
          path={routePaths.influencers.influencerProfile}
          element={<InfluencerProfile />}
        />
        <Route exact path={routePaths.irmLists.index} element={<InfluencerListsPage />} />
        <Route exact path={routePaths.irmLists.show} element={<InfluencerMyListPage />} />
        <Route exact path={routePaths.campaigns.index} element={<CampaignsManagement />} />
        <Route exact path={routePaths.campaigns.show} element={<CampaignsDetails />} />
        <Route
          exact
          path={routePaths.campaigns.influencersStatistics}
          element={<CampaignInfluencersStatistics />}
        />
        {/* Wildcard paths blocker fallback */}
        <Route path={`${routePaths.influencers.myInfluencers}/*`} element={<NotFoundError />} />
      </Route>
    </Route>

    <Route path="*" element={<NotFoundError />} />
  </ReactRoutes>
);

export default Routes;
